import { useState } from 'react';
import './App.css';
import { AiOutlineCloudUpload } from 'react-icons/ai'; // Importing icon
import Swal from 'sweetalert2'; // Import SweetAlert

function App() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files)); // Store files as an array
  };

  const handleUpload = async () => {
    setLoading(true);
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    try {
      const response = await fetch('/api/upload-scans/dicom', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json(); // Parse the response as JSON
      console.log('response', data); // Log the parsed data
      Swal.fire({
        title: 'Upload Successful!',
        html: `You can view the study at this URL: <a href="${data.viewerURL}" target="_blank">${data.viewerURL}</a>`, // Use the parsed data with a clickable link
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      console.error('Error uploading files:', error);
      Swal.fire({
        title: 'Upload Failed!',
        text: 'There was an error uploading your files. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="App"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f9f9f9',
        padding: '20px'
      }}
    >
      {/* Logo and description */}
      <header className="App-header" style={{ textAlign: 'center', marginBottom: '30px' }}>
        <h1 style={{ fontSize: '2.5rem', fontWeight: 'bold', marginBottom: '10px' }}>CogxAI</h1>
        <p style={{ fontSize: '1.1rem', color: '#555', marginBottom: '40px', maxWidth: '400px' }}>
          Upload the Inference results DICOM files and view them in the OHIF viewer.
        </p>
      </header>

      {/* File input with icon */}
      <label
        htmlFor="file-upload"
        style={{
          cursor: 'pointer',
          padding: '20px',
          border: '2px dashed #007bff',
          borderRadius: '10px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#007bff',
          marginBottom: '20px'
        }}
      >
        <AiOutlineCloudUpload size={60} />
        <span style={{ fontSize: '1rem', marginTop: '10px' }}>Click to select files</span>
      </label>
      <input
        id="file-upload"
        type="file"
        webkitdirectory="true"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />

      {/* Display selected files */}
      {files.length > 0 && (
        <div style={{ marginBottom: '20px', color: '#333', textAlign: 'center' }}>
          <p style={{ fontSize: '1rem' }}>{files.length} file(s) selected</p>
        </div>
      )}

      {/* Upload button */}
      <button
        onClick={handleUpload}
        disabled={loading || files.length === 0}
        className="btn btn-primary"
        style={{
          padding: '10px 20px',
          fontSize: '1rem',
          cursor: 'pointer',
          backgroundColor: loading || files.length === 0 ? '#ccc' : '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          transition: 'background-color 0.3s',
          minWidth: '150px'
        }}
      >
        {loading ? (
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        ) : (
          'Upload'
        )}
      </button>
    </div>
  );
}

export default App;
